import React, { Component } from "react";
import { connect } from "react-redux";
import { selectOffer } from "containers/MultistepForm/actions";
import { mergeRight } from "ramda";
import PropTypes from "prop-types";
import {BlueBtn } from "components/Controls";
import {change, Field, reduxForm} from 'redux-form';
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import { nextStep } from "../../actions";
import {
  affiliatedProviderSelector,
  trendingsVisitID,
} from "../../selectors";
import { createStructuredSelector } from "reselect";
import { AnalyticAPI } from "api/analytic";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import OfferHeader from "../OfferHeader";
import { buildOfferData } from "containers/MultistepForm/helpers";
import "./styles.scss";
import {RadioField} from '../../../../components/Fields';
import OfferBlock from '../../../../components/DataDisplay/OfferBlock';
import {addThousandsSeparator} from "../../../../utils/helpers";

class Offer extends Component {
  state = {
    detailsShown: false,
    loading: false,
    selectedOption: null,
  };

  componentDidMount = () => {
    const { offer } = this.props;

    const sortedOptions = offer.options.sort((a, b) => {
      return a.payment_period - b.payment_period;
    });

    this.setState({
      options: sortedOptions,
    });

    const defaultSelected = offer.options[offer.options.length - 1];
    if (offer.options.length > 1) {
      this.props.dispatch(
        change(
          "form",
          "paymentPeriod",
          defaultSelected.payment_period.toString()
        )
      );
    }
    this.setSelected(defaultSelected);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      approved_amount: offer.approved_amount,
      specific_provider_name: offer.provider,
      cart_total: offer.cart_total,
      bank_data_is_required: offer.bank_data_is_required,
      new_flexshopper: offer.new_flexshopper,
    });
    this.props.getSelectedOffer(option);
    this.setState({ selectedOption: option });
  };

  renderPaymentPeriodField = ({ input, meta }) => {
    return (
      <RadioField
        {...input}
        meta={meta}
        options={this.paymentPeriodOptions()}
        customChange={this.setPaymentPeriod}
      />
    );
  };

  setPaymentPeriod = (value) => {
    const option = this.props.offer.options.find(
      (option) => option.payment_period === parseInt(value)
    );

    this.setSelected(option);
  };

  paymentPeriodOptions = () => {
    const { offer } = this.props;

    return offer.options.map((option) => {
      return {
        value: `${option.payment_period.toString()} Weeks`,
        key: option.payment_period.toString(),
      };
    });
  };

  renderPaymentPeriodBlock = () => {
    const { offer } = this.props;
    const { selectedOption } = this.state;

    if (offer.options.length === 1) {
      return <OfferBlock
        row={[{
          title: 'Payment Period',
          value: `${selectedOption.payment_period} Weeks`
        }]}
      />
    } else {
      return (<div className="offer-block offer-block--100">
        <div className="offer-item offer-item__column block-padding">
          <div className="offer-item__title">Payment Period:</div>
          <Field
            name="paymentPeriod"
            component={this.renderPaymentPeriodField}
          />
        </div>
      </div>)
    }
  }

  submitForm = (e) => {
    const { selectOffer, offer, logContinueEvent } = this.props;
    const { selectedOption } = this.state;
    const { token, visitID } = this.props;
    AnalyticAPI.send_provider_analytic_event(
      token,
      "select_provider",
      offer.specific_provider_name,
      visitID
    );
    logContinueEvent()
    this.setState({ loading: true });
    selectOffer({ selectedOffer: selectedOption, body: { offer_id: selectedOption.offer_id } });
  };

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <div className="agreemnet-block__label">
            <span>Subject to additional identity verification questions.</span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { available, isExpanded, offer } = this.props;

    if (!selectedOption) {
      return "";
    }

    return (
      <div className="expanded-block-component__expanded-content">
        <OfferHeader
          available={available}
          isExpanded={isExpanded}
          dueToday={selectedOption.payment_period_rate.replace('$', '')}
          specificProviderName={selectedOption.provider}
          paymentPeriod={`${selectedOption.payment_period} Weeks`}
          paymentFrequency={selectedOption.payment_period_rate}
          approvedAmount={offer.approved_amount}
          typeOfPlan={selectedOption.type}
        />
        {isExpanded && (
          <form className="flexshopper-offer application-offer">
            <div className="step3__recommended-offer-plan offer-plan__top flex flex--wrap">
              <OfferView
                offerData={buildOfferData(selectedOption, false, false)}
              />
              {this.renderPaymentPeriodBlock()}
              <OfferView
                offerData={buildOfferData(selectedOption, false, true)}
              />
            </div>
            <BuyoutInfo
              titleTooltip={
                "You will own the item(s) and not have to pay any additional amount if you pay the full Cash Price (the Cash Price is the price we charge for a cash sale of the item (or $50 more than the price the merchant you select charges) plus sales taxes and may be higher than the price you would pay if you were to purchase the item(s) directly; if you rent multiple items at the same time, we reduce the Cash Price (and increase the Cost of Rental) by $50 for the second and each subsequent item you rent, which makes it less expensive to purchase the items before the end of the term of the Lease). After 90 days, you also have an Early Purchase Option that allows you to own your item(s) for less. Simply refer to the table in your Lease to look up the Early Purchase Option amount after each payment date. "
              }
              title={"90 Days Same As Cash Option"}
              text={`This option lets you purchase the leased merchandise within 90 days of delivery for the Cash Price.`}
            />
            {this.renderLeaseAgreement()}
            <BlueBtn
              onClick={this.submitForm}
              customClass="step3__recommended-content-btn"
              disabled={!available}
              loading={loading}
              type="submit"
            >
              Continue with Flexshopper
            </BlueBtn>
          </form>
        )}
      </div>
    );
  }
}

Offer.propTypes = {
  getSelectedOffer: PropTypes.func,
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};

Offer.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector(),
  providerName: affiliatedProviderSelector(),
  visitID: trendingsVisitID(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: (value) => dispatch(nextStep(value)),
  };
};

Offer = connect(mapStateToProps, mapDistpatchToProps)(Offer);

Offer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Offer);

export default Offer;
